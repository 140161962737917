import React from "react"
import loadable from "@loadable/component";
import "./CareerDetails.scss"
const ContentModule = loadable(() => import("../../modules/content-render"));

const Details = props => {
    return (
      <div className="career-details-section">
        <div className="content">
            <ContentModule Content={props.job_details?.data?.job_details} />
        </div>
      </div>
    )
  }
  
  export default Details
  