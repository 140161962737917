import React, { useState, useEffect } from "react";
import { Link, graphql } from 'gatsby'
import { Container, Row, Col, Modal } from "react-bootstrap"
import loadable from "@loadable/component";
import Layout from "../components/layout";
import Seo from "../components/seo"
import CareerDetails from "../components/CareerDetails/CareerDetails"
import SocialShare from "../components/SocialShare/SocialShare";
import { PageLinks } from "../common/site/page-static-links";
import FormFields from "../../static/forms/career_form.json"
import DefaultForm from "../components/forms/default-form-layout";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const MoreJobs = loadable(() => import("../components/Career/MoreJobs"));


const JobDetail = ({ data }, props) => {
    const PageData = data?.strapiCareer
    const MoreJobsList = data?.allStrapiCareer.edges
    const pageurl = typeof window !== "undefined" ? window.location.href : ""

    const [show, setShow] = useState(false)

    const handleModal = () => {
        setShow(true)
    }

    const closeModal = () => {
        setShow(false)
    }
    return (
        <Layout popularSearch="static-details">
            <div className="layout-padding-top">
                <div className="bottom-right-image-home">
                    <div className="banner-img"></div>
                </div>
                <BreadcrumbModule mainparentname={`About`} mainparent={PageLinks.about} parentname={`Careers`} parent={PageLinks.career} pagename={PageData?.title} />
                <div className="career-details">
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h1>{PageData.title}</h1>
                            </Col>
                        </Row>
                        <Row className="content-sec">
                            <Col md={7}>
                                <CareerDetails {...PageData} />
                            </Col>
                            <Col md={1}></Col>
                            <Col md={4}>
                                <div className="sidebar position-sticky scrolled">
                                    <a href="javascript:void(0)" onClick={handleModal} className="button button-filled"><icon class="icon icon-email-white"></icon><span>apply for position</span></a>
                                    <div className="line"></div>
                                    <p className="title"><b>Department</b></p>
                                    <p>{PageData.department}</p>
                                    <div className="line"></div>
                                    <p className="title"><b>Location</b></p>
                                    <p>{PageData.location}</p>
                                    <div className="line"></div>
                                    <SocialShare
                                        iconClass="icon icon-share"
                                        shareText="Share:"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {MoreJobsList && MoreJobsList.length > 0 &&
                    <MoreJobs jobs={MoreJobsList} />
                }
            </div>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="more-filters-modal contact-form"
                id="filter-modal"
            // fullscreen={"xl-down"}
            >
                <button
                    className="cancel-button"
                    type="button"
                    onClick={() => closeModal()}
                >
                    <i className="icon black-cancel" />
                </button>
                <Modal.Body>
                    <div className="modal-body-container">
                        <h2>Send us your CV</h2>
                        <div className="contact-text">
                            Please contact us using the form below or call us on{" "}
                            <a href={"tel:" + "+44 (0)207284 1222"}>+44 (0)20 7284 1222</a>.
                        </div>
                        <DefaultForm
                            fields={FormFields}
                            formtagclassname="contact-form"
                            job_title={PageData?.title}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}



export const Head = ({ data }) => {
    const PageData = data?.strapiCareer
    const metaTitle = PageData?.title;
    const metaDescription = PageData?.title;
    return (
        <Seo title={metaTitle} description={metaDescription} />
    )
}

export default JobDetail



export const query = graphql`
query ($page_id: Int) {
    strapiCareer(strapi_id: {eq: $page_id}) {
        department
        location
        salary
        title
        job_details {
          data {
            job_details
          }
        }
    }
    
    allStrapiCareer(filter: {strapi_id: {ne: $page_id}}, limit: 4) {
        edges {
            node {
                department
                title
                slug
            }
        }
    }
  }
`